// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  envName: 'development',
  apiUrl: 'https://adtech-uat.theclub.dev/api/pccw/',
  imageUrl: 'https://storage.googleapis.com/ad-tech-fe-priture-uat',
  imagePath: '../../assets/images/',
  admin_user_email:'admin@pccw.com',
  admin_pwd:'admin'
};
